import React, { FC,Fragment } from 'react';

interface IProps {
	filters: any;
	localization: object;
	onChange: (e: any) => void;
}

const ProductListerUnselectedQuickFilters: FC<IProps> = (props) => {
	const { filters, onChange } = props;

	return (
		<>
			<div className='quickfilter-title'>
				<span className='uppercase'>Snelfilters:</span>
			</div>
			<div className='quickfilter-content btn-select'>
				{filters.map((filter) => (
					<Fragment key={filter.name}>
						{filter.filterElements.map((filterElement) => (
							<button
								key={filterElement.name}
								className={`btn ${filterElement.selected ? 'selected' : ''}`}
								onClick={onChange}
								data-url={filterElement.url}
							>
								{filterElement.name}
							</button>
						))}
					</Fragment>
				))}
			</div>
		</>
	);
};

export default ProductListerUnselectedQuickFilters;
