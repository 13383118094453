import React from 'react';
import ProductListerPaging from './ProductListerPaging';

class ProductListerFooter extends React.Component {
	render() {
		const { paging, localization } = this.props;

		return (
			<footer className='cf'>
				<ProductListerPaging
					localization={localization}
					onChange={this.props.onChange}
					paging={paging}
				/>
			</footer>
		);
	}
}

export default ProductListerFooter;
