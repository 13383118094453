import React, { FC, useState } from 'react';
import ProductListerSelectedFilters from './ProductListerSelectedFilters';
import ProductListerUnselectedFilters from './ProductListerUnselectedFilters';
import ProductListerSorting from './ProductListerSorting';

interface IProps {
	data: any;
	localization: object;
	onChange: Function;
}

const ProductListerFilters: FC<IProps> = (props) => {
	const { data, localization, onChange } = props;
	const [filter, setFilter] = useState(false);

	const handleToggleFilters = (e) => {
		e.preventDefault();

		setFilter(!filter);
	};

	const closeFilter = (e) => {
		onChange(e);
		setFilter(false);
	};

	if (data) {
		const { categoryName, filters, sorting } = data;
		let totalFilterCount = 0;
		let selectedFilterCount = 0;

		if (filters) {
			filters?.forEach(function(element) {
				totalFilterCount += element.filterElements.length;
				selectedFilterCount += element.selectedFilterElements.length;
			});
		}

		let unSelectedFilterCount = totalFilterCount - selectedFilterCount;

		let selectedFilters = filters.filter(
			(selectedFilter) => selectedFilter.hasSelectedFilterElements == true
		);

		return (
			<div className='b0_12'>
				<div className='mb p p-b3-0'>
					<div className='g'>
						<ProductListerSelectedFilters
							localization={localization}
							onChange={closeFilter}
							filters={selectedFilters}
						/>
						{sorting && (
							<div className='b0_12 mb hide-b4'>
								<ProductListerSorting
									localization={localization}
									onChange={onChange}
									sorting={sorting}
								/>
							</div>
						)}
						{(unSelectedFilterCount > 0 || selectedFilterCount > 0) && (
							<div className='b0_12 mb hide-b4'>
								<a
									className={`btn btn-full toggleFilter ${filter ? 'active' : ''}`}
									onClick={(e) => handleToggleFilters(e)}
									href='#'
								>
									<i className='fa fa-sliders'></i>&nbsp;
									{localization['productlister.filters.show.text']}
								</a>
							</div>
						)}
						<div className={`b0_12 filter-container ${filter ? 'open' : ''}`}>
							<ProductListerUnselectedFilters
								localization={localization}
								onChange={closeFilter}
								filters={filters}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}
	return <></>;
};

export default ProductListerFilters;
