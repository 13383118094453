import React from 'react';
import ProductListerFilters from './ProductListerFilters';
import ProductListerSubnavigation from './ProductListerSubnavigation';
import ProductListerSubnavigationDropdown from './ProductListerSubnavigationDropdown';

class ProductListerSidebar extends React.Component {
	render() {
		const { data, localization, extensions } = this.props;

		if (data) {
			let numberOfResultsText =
				data.numberOfResults == 1
					? localization['productlister.header.products.singular']
					: localization['productlister.header.products.plural'];

			return (
				<aside id='side' className='sidebar alt b0_12 b4_06 b6_04 side'>
					<div className='g'>
						{extensions.Lister && (
							<>
								<ProductListerSubnavigationDropdown data={data} localization={localization} />
								<div className='b0_12 hide-b4'>
									<div className='p'>
										<h1 className='head head-display'>{data.categoryName}</h1>
										<div>
											<div className='head head5 mb'>
												{data.numberOfResults} {numberOfResultsText}
											</div>
										</div>
									</div>
								</div>
							</>
						)}
						<ProductListerFilters
							localization={localization}
							onChange={this.props.onChange}
							data={data}
						/>
						<ProductListerSubnavigation data={data} />
					</div>
				</aside>
			);
		}
		return <aside id='side' className='sidebar alt gi b0_12 b4_06 b6_04 side'></aside>;
	}
}

export default ProductListerSidebar;
