import React from 'react';

class ProductCompare extends React.Component {
	onChangeUpdateCompare(e, favorite) {
		const { sku, index, updateCompareProduct, isFetchingCompare, source } = this.props;

		if (!isFetchingCompare) {
			e.preventDefault();

			updateCompareProduct(sku, favorite, index, source);
		}
	}

	onClickUpdateCompare(e) {
		//prevent label->input from triggering click event twice
		e.stopPropagation();
	}

	render() {
		const { isCompare, label, pdp } = this.props;

		if (isCompare === null) {
			return null;
		}

		return (
			<div
				className={`form-field ${pdp ? 'compare-pdp' : 'compare-listitem'}`}
				data-castlecss-field
			>
				<label className='input-control input-checkbox mb-0'>
					{label}
					<input
						type='checkbox'
						checked={isCompare}
						onChange={(e) => this.onChangeUpdateCompare(e, !isCompare)}
						onClick={this.onClickUpdateCompare}
					/>
					<span className='input-indicator'></span>
				</label>
			</div>
		);
	}
}

export default ProductCompare;
