import React from 'react';
import ProductListerItem from './ProductListerItem';

class ProductListerItems extends React.Component {
	render() {
		const { products, localization, extensions, onChangeProduct, addProductToBasketBySku } = this.props;
		let productItems = [];

		if (products != null) {
			productItems = products.map((product, index) => (
				<ProductListerItem
					extensions={extensions}
					localization={localization}
					key={product.sku + '_' + index}
					{...product}
					onChangeProduct={onChangeProduct}
					addProductToBasketBySku={addProductToBasketBySku}
					index={index}
					updateCompareProduct={this.props.updateCompareProduct}
					isFetchingCompare={this.props.isFetchingCompare}
				/>
			));
		}
		return (
			<section className='list mb-2x list-click'>
				<div className='g g-equal-height'>{productItems}</div>
			</section>
		);
	}
}

export default ProductListerItems;
