import React from 'react';

class ProductListerSearchHeader extends React.Component {
	render() {
		const { isEmpty, numberOfResults, searchData, localization } = this.props;

		let numberOfResultsText =
			numberOfResults == 1
				? localization['productsearch.header.results.singular']
				: localization['productsearch.header.results.plural'];
		let suggestions = searchData.suggestions;

		let suggestionsElement = false;
		let emptyMessageElement = false;

		if (suggestions) {
			const suggestionElements = suggestions
				.map((suggestion, index) => (
					<a key={index} href={suggestion.url}>
						{suggestion.value}
					</a>
				))
				.reduce((accu, elem) => {
					return accu === null ? [elem] : [...accu, ',', elem];
				});

			suggestionsElement = (
				<div className='head head5 notify-negative'>
					{localization['productsearch.header.didyoumean']}: {suggestionElements}
				</div>
			);
		}

		if (isEmpty) {
			emptyMessageElement = <div>{localization['productsearch.header.noresultsmessage']}</div>;
		}

		var searchTermHeader = {
			__html:
				"'" +
				searchData.searchTerm +
				"' " +
				localization['productsearch.header.yielded'] +
				' ' +
				numberOfResults +
				' ' +
				numberOfResultsText,
		};

		return (
			<header className='b0_12 cf'>
				<div className='block p p-b3-0'>
					<h1 className='head head-display' dangerouslySetInnerHTML={searchTermHeader}></h1>
					{emptyMessageElement}
					{suggestionsElement}
				</div>
			</header>
		);
	}
}

export default ProductListerSearchHeader;
