import React from 'react';

class ProductListerSorting extends React.Component {
	render() {
		const { sorting } = this.props;
		let sortingItems = [];
		if (sorting) {
			sortingItems = sorting.map((sort, index) => (
				<option defaultChecked={sort.selected} key={index} value={sort.url}>
					{sort.name}
				</option>
			));
		}

		return (
			<select onChange={(e) => this.props.onChange(e)} data-castlecss-select>
				{sortingItems}
			</select>
		);
	}
}

export default ProductListerSorting;
