import React from 'react';
import $ from 'jquery';

class ProductListerDescription extends React.Component {
	componentDidMount() {
		if ($('[data-category-html]').html() != $('.category-html-holder').html()) {
			$('.category-html-holder').html($('[data-category-html]').html());
		}
	}

	shouldComponentUpdate() {
		return false;
	}

	render() {
		return <div className='category-html-holder mb-2x'></div>;
	}
}
export default ProductListerDescription;
