import React from 'react';

class ProductListerSubnavigation extends React.Component {

	shouldComponentUpdate() {
		return false;
	}

	render() {
		const { data } = this.props;
		if (!data) {
			return false;
		}

		const { subnavigation } = data;

		if (!subnavigation) {
			return false;
		}

		//const { subnavigation } = data;
		const subnavigationElements = subnavigation.subnavigationElements;

		let subnavigationItems = [];
		subnavigationItems = subnavigationElements.map((item, index) => (
			<li
				key={index}
				itemScope='itemscope'
				itemType='http://www.schema.org/SiteNavigationElement'
				className=''
			>
				<a itemProp='url' href={item.url}>
					<span itemProp='name'>{item.title}</span>
				</a>
			</li>
		));

		return (
			<div className='b0_12 hide show-b4'>
				<nav
					itemScope='itemscope'
					itemType='http://www.schema.org/SiteNavigationElement'
					className='block alt p p-b3-0'
				>
					<h3 className='head head5'>{subnavigation.parentElement.title}</h3>

					<ul className='side-menu links-more toggle-menu cf'>{subnavigationItems}</ul>
				</nav>
			</div>
		);
	}
}

export default ProductListerSubnavigation;
