import React from 'react';

class ProductListerPaging extends React.Component {
	render() {
		let pagingElements = [];
		let pagerClass = 'paging b0_12';

		function getPagingElement(text, href, ajaxUrl, onClick, className, rel, key) {
			return (
				<li key={key} className={className}>
					<a onClick={onClick} href={href} data-url={ajaxUrl} rel={rel}>
						<span>{text}</span>
					</a>
				</li>
			);
		}

		function getPagingElementWithoutUrl(text, className, key) {
			return (
				<li key={key} className={className}>
					<span>{text}</span>
				</li>
			);
		}

		if (this.props.paging != null && this.props.paging.pagingElements != null) {
			const previousPageUrl = this.props.paging.previousPageUrl;
			const nextPageUrl = this.props.paging.nextPageUrl;
			const previousPageAjaxUrl = this.props.paging.previousPageAjaxUrl;
			const nextPageAjaxUrl = this.props.paging.nextPageAjaxUrl;

			if (previousPageUrl) {
				pagingElements.push(
					getPagingElement(
						'\u003C',
						previousPageUrl,
						previousPageAjaxUrl,
						(e) => this.props.onChange(e),
						'prev',
						'prev',
						'prev'
					)
				);
			} else {
				pagingElements.push(getPagingElementWithoutUrl('\u003C', 'prev disabled', 'prev'));
			}

			pagingElements = [
				...pagingElements,
				...this.props.paging.pagingElements.map((pagingElement, index) => {
					if (pagingElement.type == 'gap') {
						return getPagingElementWithoutUrl('...', 'gap', index);
					}

					var classNames = '';
					if (pagingElement.type == 'currentPage') {
						classNames = 'current';
					}
					return getPagingElement(
						pagingElement.displayNumber,
						pagingElement.url,
						pagingElement.ajaxUrl,
						(e) => this.props.onChange(e),
						classNames,
						'',
						index
					);
				}),
			];

			if (nextPageUrl) {
				pagingElements.push(
					getPagingElement(
						'\u003E',
						nextPageUrl,
						nextPageAjaxUrl,
						(e) => this.props.onChange(e),
						'next',
						'next',
						'next'
					)
				);
			} else {
				pagingElements.push(getPagingElementWithoutUrl('\u003E', 'next disabled', 'next'));
			}

			// if no extra pages dont show pager
			if (!nextPageUrl && !previousPageUrl) {
				pagerClass = pagerClass + ' hide';
			}
		}

		return (
			<nav className={pagerClass}>
				<ul className='cf'>{pagingElements}</ul>
			</nav>
		);
	}
}

export default ProductListerPaging;
