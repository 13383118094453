import React from 'react';

class ProductListerEmpty extends React.Component {
	render() {
		const { localization } = this.props;

		return (
			<div>
				<header>
					<div className=''>
						<div className=''>{localization['productlister.empty.text']}</div>
					</div>
				</header>
			</div>
		);
	}
}

export default ProductListerEmpty;
