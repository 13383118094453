import React from 'react';
import { render } from 'react-dom';
import configureMainStore from 'general/configureMainStore';
import Root from './Root';
import reducers from './reducers';

function run(element, initialData, localizations, extensions, synchronizerToken) {
	const initialState = {
		productLister: {
			productListers: { initial: initialData },
			currentProductLister: initialData,
			currentProductListerUrl: 'initial',
			isFetching: false,
			isFetchingCompare: true, //test this
			compareProductSKUs: [],
		},
		localizations: localizations,
		extensions: extensions,
		synchronizerToken: synchronizerToken,
	};

	const store = configureMainStore(initialState, reducers);
	window.__MASTER_REACT_APP__ = element.id;
	render(<Root useRouter={true} store={store} />, element);
}

export default { run: run };
