import React from 'react';

class ProductListerSelectedFiltersItem extends React.Component {
	render() {
		const { selectedFilterElements, localization } = this.props;

		if (selectedFilterElements != null) {
			const selectedFilterElementsElements = selectedFilterElements.map((filterElement, index) => (
				<a
					key={index}
					href='#'
					data-url={filterElement.url}
					onClick={(e) => this.props.onChange(e)}
					title={localization['productlister.selectedfilters.remove.text']}
				>
					{filterElement.name}
					<i className='fa fa-times'></i>
				</a>
			));

			return (
				<React.Fragment>
					<h4 className='head head4 cf'>{this.props.name}</h4>
					<div className='mb cf'>{selectedFilterElementsElements}</div>
				</React.Fragment>
			);
		}
		return false;
	}
}

export default ProductListerSelectedFiltersItem;
