import React from 'react';
import ProductListerUnselectedFiltersCheckbox from './ProductListerUnselectedFiltersCheckbox';
import ProductListerUnselectedFiltersColor from './ProductListerUnselectedFiltersColor';

const limit = 7;

class ProductListerUnselectedFilters extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isOpen: false,
			limit: limit,
		};
	}

	toggle(filters) {
		this.setState({
			isOpen: !this.state.isOpen,
			limit: filters.length ? this.state.limit : filters.length,
		});
	}

	render() {
		const { filters, localization } = this.props;

		if (filters == null) {
			return <div className='filters'></div>;
		}

		if (this.state.isOpen === true) {
			this.state.limit = filters.length;
		} else {
			this.state.limit = limit;
		}

		// newFilters to filter out empty or quickFilter elements
		var newFilters = filters.filter(function(filter) {
			return filter.filterElements.length > 0 && filter.quickFilter === false;
		});

		const Slicedfilters = newFilters.slice(0, this.state.limit);

		const unselectedFiltersFilters = Slicedfilters.map((filter, index) => {
			let unselectedFilter = false;

			switch (filter.type) {
				case 'checkbox':
					unselectedFilter = (
						<ProductListerUnselectedFiltersCheckbox
							localization={localization}
							onChange={this.props.onChange}
							filterkey={index}
							key={index}
							{...filter}
						/>
					);
					break;
				case 'swatch':
					unselectedFilter = (
						<ProductListerUnselectedFiltersColor
							localization={localization}
							onChange={this.props.onChange}
							filterkey={index}
							key={index}
							{...filter}
						/>
					);
					break;
				default:
					unselectedFilter = (
						<ProductListerUnselectedFiltersCheckbox
							localization={localization}
							onChange={this.props.onChange}
							filterkey={index}
							key={index}
							{...filter}
						/>
					);

					break;
			}

			return unselectedFilter;
		});

		return (
			<div className='filters'>
				<ul className='form-fields links'>
					{unselectedFiltersFilters}

					{filters.length > limit && (
						<li className='showmore'>
							<button className='link-more' onClick={this.toggle.bind(this)}>
								<span>
									{this.state.isOpen
										? localization['productlister.filters.hide.text']
										: localization['productlister.filters.show.text']}
								</span>
							</button>
						</li>
					)}
				</ul>
			</div>
		);
	}
}

export default ProductListerUnselectedFilters;
