import React from 'react';

class ProductListerItemVariant extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			isButtonActive: this.props.isEbike ? false : this.props.selected,
		};
	}

	createColorVariantSelector(productVariant, isEbike) {
		let variantColorSelector = (
			<a
				id={productVariant.uuid}
				style={{ backgroundColor: productVariant.color }}
				data-url={productVariant.ajaxUrl}
				data-index={this.props.index}
				disabled={this.props.isFetching}
				onClick={this.props.onChangeProduct}
				title={productVariant.name}
			>
				{productVariant.color}
			</a>
		);

		if (isEbike) {
			variantColorSelector = (
				<span style={{ backgroundColor: productVariant.color }} title={productVariant.name}>
					{productVariant.color}
				</span>
			);
		}

		return variantColorSelector;
	}

	render() {
		const { productVariant, index, isEbike } = this.props;

		const variantColorSelector = this.createColorVariantSelector(productVariant, isEbike);

		if (true) {
			if (this.props.variantType == 'colorCode') {
				return (
					<li key={index} className={'color' + (this.state.isButtonActive ? ' active' : '')}>
						{variantColorSelector}
					</li>
				);
			} else if (this.props.variantType == 'defaultAndSwatchImage') {
				return (
					<li key={index} className={'size' + (this.state.isButtonActive ? ' active' : '')}>
						<a
							id={productVariant.uuid}
							className='size-link'
							data-url={productVariant.ajaxUrl}
							disabled={this.props.isFetching}
							data-index={this.props.index}
							onClick={this.props.onChangeProduct}
						>
							{productVariant.name}
						</a>
					</li>
				);
			}
		}
	}
}

export default ProductListerItemVariant;
