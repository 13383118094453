import React from 'react';
import { connect } from 'react-redux/dist/react-redux.min.js';
import Form from 'react-validation/build/form.js';
import Input from 'react-validation/build/input.js';
import Button from 'react-validation/build/button.js';
import validator from 'validator/validator.js';
import ProductListerItemVariant from './ProductListerItemVariant';
import ProductCompare from 'shared/components/ProductCompare';
import ProductPriceElement from 'shared/components/ProductPriceElement';
import ProductImageElement from 'shared/components/ProductImageElement';
import ProductReviews from '../shared/components/ProductReviews';
import { pushProductClick } from 'shared/libs/gaevents';

/**

 * Validation rules for quantity form

 */

//Expect currying with error argument

const numeric = function(error) {
	// Abuse closure :)

	return function(value) {
		if (!validator.isNumeric(value.toString())) {
			return <span className='alert-negative'>{error}</span>;
		}
	};
};

class ProductListerItem extends React.Component {
	constructor(props, context) {
		super(props);

		// Call with pre-defined error based on props
		const numericWithError = numeric(props.localization['productlister.item.quantity.error']);
		// Save to instance property
		this.validationsNumeric = [numericWithError];

		this.state = {
			addToBasketQuantity: 1,
		};

		// this.toggleClass = this.toggleClass.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props?.addToBasketQuantity) {
			this.setState({ addToBasketQuantity: this.props.addToBasketQuantity });
		}
	}

	createProductVariantsElement(variantGroups, isEbike) {
		if (variantGroups != null) {
			const variantGroupItems = variantGroups.map((variantGroup, index) =>
				this.createProductVariantElement(variantGroup, index, isEbike)
			);

			return <div className='list-item-variants cf'>{variantGroupItems}</div>;
		} else {
			return false;
		}
	}

	createProductVariantElement(variantGroup, indexKey, isEbike) {
		let productVariantItems = false;
		if (variantGroup.productVariants != null && variantGroup.presentationOption == 'colorCode') {
			productVariantItems = variantGroup.productVariants.map((productVariant, index) => (
				<ProductListerItemVariant
					productVariant={productVariant}
					key={index}
					index={this.props.index}
					onChangeProduct={this.props.onChangeProduct}
					selected={productVariant.selected}
					variantType={variantGroup.presentationOption}
					isEbike={isEbike}
				/>
			));

			return (
				<ul data-name={variantGroup.name} key={indexKey} className='color-list list-item-variant'>
					{productVariantItems}
				</ul>
			);
		} else if (
			variantGroup.productVariants != null &&
			variantGroup.presentationOption == 'swatchImage'
		) {
			return;
		} else if (
			variantGroup.productVariants != null &&
			variantGroup.presentationOption == 'defaultAndSwatchImage'
		) {
			productVariantItems = variantGroup.productVariants.map((productVariant, index) => (
				<ProductListerItemVariant
					productVariant={productVariant}
					key={index}
					index={this.props.index}
					onChangeProduct={this.props.onChangeProduct}
					selected={productVariant.selected}
					variantType={variantGroup.presentationOption}
					isEbike={isEbike}
				/>
			));

			return (
				<ul
					data-name={variantGroup.name}
					key={indexKey}
					className='size-list-large list-item-variant fl'
				>
					{productVariantItems}
				</ul>
			);
		} else if (variantGroup.productVariants != null) {
			productVariantItems = variantGroup.productVariants.map((productVariant, index) => (
				<option key={index} value={productVariant.uuid} data-url={productVariant.ajaxUrl}>
					{productVariant.name}
				</option>
			));
			return (
				<select
					data-castlecss-select
					key={indexKey}
					data-name={variantGroup.name}
					disabled={this.props.isFetching}
					value={this.props.uuid}
					data-index={this.props.index}
					onChange={this.props.onChangeProduct}
				>
					{productVariantItems}
				</select>
			);
		}
	}

	createAddToBasketFormElement() {
		return (
			<Form className='form' onSubmit={this.onAddProductToBasket.bind(this)}>
				<div className='form-fields'>
					<div className='form-field'>
						<div className='input-combined'>
							<Input
								className='form-input'
								type='text'
								name='quantity'
								value={this.state.addToBasketQuantity}
								onChange={(e) => this.onChangeAddToBasketQuantity(e)}
								validations={this.validationsNumeric}
							/>
							<Button className='btn btn-submit'>
								<i className='fa fa-shopping-basket'></i>
							</Button>
						</div>
					</div>
				</div>
			</Form>
		);
	}

	onChangeAddToBasketQuantity(e) {
		this.setState({ addToBasketQuantity: e.target.value });
	}

	onAddProductToBasket(e) {
		e.preventDefault();
		this.props.addProductToBasketBySku(
			this.props.sku,
			this.props.index,
			this.state.addToBasketQuantity
		);
	}

	render() {
		const {
			productLister,
			localization,
			extensions,
			variantGroups,
			name,
			link,
			images,
			description,
			prices,
			index,
			sku,
			sticker,
			subtitles,
			isEbike,
			productReviews,
		} = this.props;

		const { compareProductSKUs } = productLister;

		const isCompareProduct = (compareProductSKUs && compareProductSKUs.indexOf(sku) != -1) ?? false;

		const productVariantsElement = this.createProductVariantsElement(variantGroups, isEbike);

		let subtitleItems = [];

		if (subtitles != null) {
			subtitleItems = subtitles.slice(0, 2).map((subtitle, index) => (
				<div key={index} className='list-item-subtitle'>
					{subtitle}
				</div>
			));
		}

		let reviews = {};
		if (productReviews != null) {
			reviews = productReviews;
		}

		let privateLeasePrice = prices.find((price) => price.name == 'PrivateLeasePrice');

		const clickEventSource = extensions.Lister
			? productLister.currentProductLister.categoryName
			: 'Zoekresultaten';
		const compareEventSource = extensions.Lister ? 'lister' : 'search';

		const onClickProduct = (e) => {
			//NOTE: do NOT use e.preventDefault() !
			//wishing "product" was a prop here...
			pushProductClick(this.props, clickEventSource, index + 1);
		};

		return (
			<article className='b0_12 b2_06 b3_12 b5_08' onClick={onClickProduct} data-tag-sku={sku}>
				<div className='list-item'>
					<ProductCompare
						sku={sku}
						index={index}
						isCompare={isCompareProduct}
						isFetchingCompare={this.props.isFetchingCompare}
						updateCompareProduct={this.props.updateCompareProduct}
						label={localization['compare.checkbox.label']}
						pdp={false}
						source={compareEventSource}
					/>
					<a href={link} className='list-img'>
						<ProductImageElement images={images} name={name} />
					</a>
					{sticker != null && sticker != '' && (
						<img className='product-sticker' alt={name} src={sticker} />
					)}
					<div className='list-item-title'>
						<h2 className='head head5'>
							<a href={link}>{name}</a>
						</h2>
						<ProductReviews
							averageRating={reviews.averageRating}
							totalRatingsCount={reviews.totalRatingsCount}
							labelSingular={localization['product.reviews.title.text']}
							labelPlural={localization['product.reviews.title.plural.text']}
							center
						/>
						{subtitleItems}
					</div>
					<div className='list-item-desc'>
						{description && <p>{description}</p>}
						{productVariantsElement}
					</div>
					<footer className='list-item-footer g'>
						<ProductPriceElement
							prices={prices}
							localization={localization}
							fromLabel={localization['productlister.item.from.text']}
							isEbike={isEbike}
							gridClass={'b0_12'}
						/>
						<div className='list-item-actions b0_12'>
							<a href={link} className='btn full' data-tag-cta>
								{localization['productlister.item.show.text']}
							</a>
						</div>
					</footer>
				</div>
			</article>
		);
	}
}

function mapStateToProps(state) {
	return {
		productLister: state.productLister,
	};
}

export default connect(mapStateToProps, null)(ProductListerItem);
