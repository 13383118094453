import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { createGeneralReducers, createGeneralComponents } from 'shared/general';
import { DealerfinderActions } from 'dealerfinder/types';
import { CompareActions } from 'compare/types';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Define the Reducers that will always be present in the application
const staticReducers = {
	...createGeneralReducers(),
};
export default function configureMainStore(preloadedState, mainReducers, mainMiddleware) {
	const logger = createLogger({
		collapsed: true,
		predicate: (getState, action) => {
			//add or remove actions that do not need to be included in redux-logger
			//action.type === 'ADD_DATA',
			if (action.type === 'REQUEST_PRODUCT') {
				return false;
			}
			if (action.type === 'RECEIVE_PRODUCT') {
				return false;
			}
			if (action.type === DealerfinderActions.RENDER_COMPLETED) {
				return false;
			}
			if (
				action.type === CompareActions.REQUEST_REMOVEPRODUCTFROMCOMPARE ||
				action.type === CompareActions.RECEIVE_REMOVEPRODUCTFROMCOMPARE ||
				action.type === CompareActions.REQUEST_CHECKPRODUCTSWITHLOCATION ||
				action.type === CompareActions.RECEIVE_CHECKPRODUCTSWITHLOCATION
			) {
				return false;
			}
			return true;
		},
	});

	let middleware = [thunkMiddleware];
	if (mainMiddleware) {
		middleware = [thunkMiddleware, ...mainMiddleware];
	}
	if (process.env.NODE_ENV !== 'production') {
		middleware = [...middleware, logger];
	}

	const store = createStore(
		createReducer(mainReducers),
		preloadedState,
		composeEnhancers(applyMiddleware(...middleware))
	);

	// Add a dictionary to keep track of the registered async reducers
	store.asyncReducers = {};

	// Create an inject reducer function
	// This function adds the async reducer, and creates a new combined reducer
	store.injectReducer = (key, asyncReducer) => {
		store.asyncReducers[key] = asyncReducer;
		store.replaceReducer(createReducer(mainReducers, store.asyncReducers));
	};

	createGeneralComponents(store);

	window.store = store;

	return store;
}

function createReducer(mainReducers, asyncReducers) {
	return combineReducers({
		...mainReducers,
		...staticReducers,
		...asyncReducers,
	});
}
