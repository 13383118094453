export interface ICompare {
	isFetching: boolean;
	mailSend: boolean;
	products: ICompareProduct[];
	groups: ICompareGroup[];
	showError: boolean;
	allowSubscribeNewsletter: boolean;
}

export interface ICompareProduct {
	images: IImage[];
	price: IPrice;
	privateLeasePrice: IPrice;
	sku: string;
	title: string;
	link: string;
	isEbike: boolean;
}

export interface IImage {
	name: string;
	altText: string;
	imageLink: string;
}

export interface IPrice {
	fraction: number;
	whole: number;
	name: string;
}

export interface ICompareGroup {
	id: string;
	title: string;
	attributes: ICompareAttribute[];
}

export interface ICompareAttribute {
	id: string;
	title: string;
	differentValues: boolean;
	productValues: ICompareProductValue[];
}

export interface ICompareProductValue {
	sku: string;
	values: string[];
	type: IAttributeType;
}

export interface History {
	push(path: string, state?: any): void;
	goBack(): any;
}

export enum IAttributeType {
	string = 'string',
	color = 'color',
}

export enum CompareActions {
	REQUEST_REMOVEPRODUCTFROMCOMPARE = 'REQUEST_REMOVEPRODUCTFROMCOMPARE',
	RECEIVE_REMOVEPRODUCTFROMCOMPARE = 'RECEIVE_REMOVEPRODUCTFROMCOMPARE',

	REQUEST_CHECKPRODUCTSWITHLOCATION = 'REQUEST_CHECKPRODUCTSWITHLOCATION',
	RECEIVE_CHECKPRODUCTSWITHLOCATION = 'RECEIVE_CHECKPRODUCTSWITHLOCATION',

	REQUEST_SENDCOMPAREEMAIL = 'REQUEST_SENDCOMPAREEMAIL',
	RECEIVE_SENDCOMPAREEMAIL = 'RECEIVE_SENDCOMPAREEMAIL',
	RECEIVE_RESETCOMPAREEMAIL = 'RECEIVE_RESETCOMPAREEMAIL',
}

interface RequestRemoveProductFromCompareAction {
	type: typeof CompareActions.REQUEST_REMOVEPRODUCTFROMCOMPARE;
	sku: string;
}

interface ReceiveRemoveProductFromCompareAction {
	type: typeof CompareActions.RECEIVE_REMOVEPRODUCTFROMCOMPARE;
	data: any;
	sku: string;
}

interface RequestCheckProductsWithLocationAction {
	type: typeof CompareActions.REQUEST_CHECKPRODUCTSWITHLOCATION;
	location: string;
}

interface ReceiveCheckProductsWithLocationAction {
	type: typeof CompareActions.RECEIVE_CHECKPRODUCTSWITHLOCATION;
	diff: string[];
	data: ICompare;
}

interface RequestSendCompareEmailAction {
	type: typeof CompareActions.REQUEST_SENDCOMPAREEMAIL;
}

interface ReceiveSendCompareEmailAction {
	type: typeof CompareActions.RECEIVE_SENDCOMPAREEMAIL;
	data: any;
}
interface ReceiveResetCompareEmailAction {
	type: typeof CompareActions.RECEIVE_RESETCOMPAREEMAIL;
}

export type CompareActionsTypes =
	| RequestRemoveProductFromCompareAction
	| ReceiveRemoveProductFromCompareAction
	| RequestCheckProductsWithLocationAction
	| ReceiveCheckProductsWithLocationAction
	| RequestSendCompareEmailAction
	| ReceiveSendCompareEmailAction
	| ReceiveResetCompareEmailAction;
