import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ProductListerApp from './ProductListerApp';
import { Provider } from 'react-redux/dist/react-redux.min.js';

class Root extends React.Component {
	render() {
		if (this.props.useRouter) {
			return (
				<Provider store={this.props.store}>
					<BrowserRouter>
						<Switch>
							{/* no real routing tracking the search entry */}
							<Route component={ProductListerApp} />
						</Switch>
					</BrowserRouter>
				</Provider>
			);
		} else {
			return (
				<Provider store={this.props.store}>
					<ProductListerApp />
				</Provider>
			);
		}
	}
}

export default Root;