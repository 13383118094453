import React from 'react';

class ProductListerSubnavigationDropdown extends React.Component {
	shouldComponentUpdate() {
		return false;
	}

	onChange(e) {
		var targetUrl = e.target.value;
		window.location = targetUrl;
	}

	render() {
		const { data, localization } = this.props;

		if (!data) {
			return false;
		}

		const { subnavigation } = data;

		if (!subnavigation) {
			return false;
		}

		//const { subnavigation } = data;
		const subnavigationElements = subnavigation.subnavigationElements;
		const categoryTitle = localization['productlister.subnavigation.title'];

		let subnavigationItems = [];
		subnavigationItems = subnavigationElements.map((item, index) => (
			<option key={index} value={item.url}>
				{item.title}
			</option>
		));

		return (
			<div className='b0_12 hide-b4'>
				<div className='p p-b3-0'>
					<select onChange={(e) => this.onChange(e)} data-castlecss-select>
						<option>{categoryTitle}</option>
						{subnavigationItems}
					</select>
				</div>
			</div>
		);
	}
}

export default ProductListerSubnavigationDropdown;
