import React from 'react';

class ProductListerUnselectedFiltersColor extends React.Component {
	constructor() {
		super();

		this.state = { filterOpen: false };
	}

	handleToggleFilter(e) {
		e.preventDefault();
		this.setState({
			filterOpen: !this.state.filterOpen,
		});
	}

	render() {
		const filterElements = this.props.filterElements;
		const name = this.props.name;
		const filterkey = this.props.filterkey;

		let filterOpenClassNames = this.state.filterOpen
			? 'filter b0_12 expandable closed'
			: 'filter b0_12 expandable open';

		const filterElementsElements = filterElements.map((filterElement, index) => {
			let style = {
				background: filterElement.color,
			};
			let filteritemkey = 'checkboxfilter_' + filterkey + '_' + index;

			return (
				<li key={index} className='cf'>
					<label className='input-control input-checkbox input-color' htmlFor={filteritemkey}>
						<span className='input-color-text'>
							{filterElement.name} <em>({filterElement.numberOfResults})</em>
						</span>
						<input
							onClick={(e) => this.props.onChange(e)}
							id={filteritemkey}
							data-url={filterElement.url}
							type='checkbox'
							checked={filterElement.selected}
						/>
						<span className='input-indicator color-indicator'>
							<span style={style} title={filterElement.name}></span>
						</span>
					</label>
				</li>
			);
		});

		return (
			<li className={filterOpenClassNames}>
				<div className='checks color'>
					<span className='filter-title head head6 mb' onClick={(e) => this.handleToggleFilter(e)}>
						{name}
						<i className='fa' aria-hidden='true'></i>
					</span>
					<div className='filter-content cf'>
						<ul className='cf'>{filterElementsElements}</ul>
					</div>
				</div>
			</li>
		);
	}
}

export default ProductListerUnselectedFiltersColor;
