import React from 'react';

class ProductListerUnselectedFiltersCheckbox extends React.Component {
	constructor() {
		super();

		this.state = { filterOpen: false };
	}

	handleToggleFilter(e) {
		e.preventDefault();

		this.setState({
			filterOpen: !this.state.filterOpen,
		});
	}

	render() {
		const filterElements = this.props.filterElements;
		const name = this.props.name;
		const filterkey = this.props.filterkey;
		const filterDescription = this.props.description;

		let filterOpenClassNames = this.state.filterOpen
			? 'filter b0_12 expandable closed'
			: 'filter b0_12 expandable open';

		const filterElementsElements = filterElements.map((filterElement, index) => {
			let filteritemkey = 'checkboxfilter_' + filterkey + '_' + index;

			return (
				<li key={index} className='cf'>
					<label htmlFor={filteritemkey} className='input-control input-checkbox'>
						{filterElement.name} <em>({filterElement.numberOfResults})</em>
						<input
							onChange={(e) => this.props.onChange(e)}
							id={filteritemkey}
							data-url={filterElement.url}
							type='checkbox'
							checked={filterElement.selected}
						/>
						<span className='input-indicator'></span>
					</label>
				</li>
			);
		});

		return (
			<li className={filterOpenClassNames}>
				{filterElementsElements.length != '0' && (
					<div className='filter-content-wrapper'>
						<span className='filter-title head head6' onClick={(e) => this.handleToggleFilter(e)}>
							{name}
							<i className='fa' aria-hidden='true'></i>
						</span>
						{filterDescription != null && filterDescription != '' && (
							<div className='filter-description-button'>
								<div className='button-info cor-icon-info'></div>
							</div>
						)}
						{filterDescription != null && filterDescription != '' && (
							<div className='filter-description'>
								<div className='filter-description-inner'>
									<div className='filter-description-close cor-icon-cross'></div>
									<div className='filter-description-content'>
										<p className='m-0' dangerouslySetInnerHTML={{ __html: filterDescription }}></p>
									</div>
								</div>
							</div>
						)}
						<div className='filter-content'>
							<ul className='cf'>{filterElementsElements}</ul>
						</div>
					</div>
				)}
			</li>
		);
	}
}

export default ProductListerUnselectedFiltersCheckbox;
