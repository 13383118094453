import React from 'react';

export const fractionCalc = (price) => {
	let newPrice;
	if (price < 1) {
		return null;
	} else {
		newPrice = price.padStart(2, '0');
		return (
			<>
				,<span className='sup'>{newPrice}</span>
			</>
		);
	}
};
/**
 *
 * @param {*} productPrice
 * @param {*} salePrice
 */
export const renderPrice = (productPrice, salePrice) => {
	if (salePrice !== null) {
		return (
			<>
				<del>
					&euro;{productPrice.whole}
					{fractionCalc(productPrice.fraction)}
				</del>
				<ins className='price'>
					&euro;{salePrice.whole}
					{fractionCalc(salePrice.fraction)}
				</ins>
			</>
		);
	} else {
		return (
			<div className='price'>
				&euro;{productPrice.whole}
				{fractionCalc(productPrice.fraction)}
			</div>
		);
	}
};

export default class ProductPriceElement extends React.Component {
	render() {
		const { prices, isEbike, isLease, gridClass, fromLabel } = this.props;
		let productPrice = false;
		let productPriceElement = null;
		let salePrice = null;
		if (prices) {
			let filterName = isLease ? 'PrivateLeasePrice' : 'ListPrice';

			productPrice =
				prices.find(function(price) {
					if (price.name === filterName) {
						return price;
					}
				}) ?? null; //turn undefined value into null value
			salePrice =
				prices.find((price) => {
					if (price.name == 'SalePrice') {
						return price;
					}
				}) ?? null; //turn undefined value into null value

			//use salePrice if it is the only price
			if (!productPrice && salePrice) {
				productPrice = salePrice;
				salePrice = null;
			}

			//do not use salePrice if it is the same as productPrice
			if (
				productPrice &&
				salePrice &&
				salePrice.whole === productPrice.whole &&
				salePrice.fraction === productPrice.fraction
			) {
				salePrice = null;
			}

			productPriceElement = productPrice ? (
				<div className={`list-item-prices prices ${gridClass}`}>
					<div className='prices'>
						{isEbike && !salePrice && fromLabel && (
							// Show text for ebikes
							<small className='list-item-price-text'>{fromLabel}</small>
						)}
						{renderPrice(productPrice, salePrice)}
					</div>
				</div>
			) : (
				<div className='prices'></div>
			);
		}
		return productPriceElement;
	}
}
