import React from 'react';
import { bindActionCreators } from 'redux/dist/redux.min.js';
import { connect } from 'react-redux/dist/react-redux.min.js';
import * as actionCreators from './actions';
import ProductListerSidebar from './ProductListerSidebar';
import ProductListerMain from './ProductListerMain';
import ProductListerSearchHeader from './ProductListerSearchHeader';

import { Select } from 'castlecss-forms';
import jQuery from 'jquery';

class ProductListerApp extends React.Component {
	constructor(props) {
		super(props);
		this.handleChange.bind(this);
	}

	componentDidMount() {
		const { getCompareData } = this.props;

		this.processRoute(true);

		Select();

		getCompareData();
	}

	componentDidUpdate(prevProps, prevState) {
		jQuery(document).trigger('DOMChanged');
		if (this.props.productLister.basketQuantity) {
			jQuery(document).trigger('basket:updated', {
				quantity: this.props.productLister.basketQuantity,
			});
		}

		if (prevProps.location.search != this.props.location.search) {
			this.processRoute();
		}
	}

	processRoute(initial) {
		if (this.props.location.state && !initial) {
			const search = this.props.location.search.substring(1);

			let filterUrl = `${this.props.productLister.currentProductLister.baseAjaxUrl}&${search}`;

			this.processChange(filterUrl);

			return true;
		} else {
			this.processChange('initial');
		}
		return false;
	}

	processChange(url) {
		this.props.fetchProductListerIfNeeded(url);
	}

	handleChange(e) {
		e.preventDefault();

		var targetUrl = '';
		var tagName = e.currentTarget.tagName.toLowerCase();

		if (tagName == 'select') {
			targetUrl = e.currentTarget.value;
		} else {
			targetUrl = e.currentTarget.getAttribute('data-url');
		}
		//scroll to top
		window.scroll(0, 0);

		this.pushHistory(targetUrl);
	}

	pushHistory(targetUrl) {
		let queryURL = '';

		if (targetUrl.length >= 0) {
			queryURL = targetUrl;
			this.props.history.push({ state: { active: true }, pathname: '', search: `${queryURL}` });
		}
	}

	handleChangeProduct(e) {
		e.preventDefault();

		var targetUrl = '';
		var tagName = e.currentTarget.tagName.toLowerCase();

		if (tagName == 'select') {
			targetUrl = jQuery(e.currentTarget)
				.find(':selected')
				.data('url');
		} else {
			targetUrl = e.currentTarget.getAttribute('data-url');
		}

		const index = e.currentTarget.getAttribute('data-index');

		this.props.fetchProductVariant(targetUrl, index);
	}

	render() {
		const { extensions, productLister, localizations } = this.props;
		const { isFetchingCompare, currentProductLister, isFetching } = productLister;

		if (extensions.Search && currentProductLister.empty) {
			return (
				<div className='g' data-localization={this.props.localizations['productlister.empty.text']}>
					<ProductListerSearchHeader
						isEmpty={currentProductLister.empty}
						localization={localizations}
						searchData={currentProductLister.search}
						numberOfResults={currentProductLister.numberOfResults}
					/>
				</div>
			)
		}

		return (
			<div
				className='g gutter-none'
				data-localization={this.props.localizations['productlister.empty.text']}
			>
				{extensions.Search && (
					<ProductListerSearchHeader
						isEmpty={currentProductLister.empty}
						localization={localizations}
						searchData={currentProductLister.search}
						numberOfResults={currentProductLister.numberOfResults}
					/>
				)}
				<ProductListerSidebar
					extensions={extensions}
					localization={localizations}
					onChange={(e) => this.handleChange(e)}
					data={currentProductLister}
				/>
				<ProductListerMain
					extensions={extensions}
					localization={localizations}
					onChange={(e) => this.handleChange(e)}
					onChangeProduct={(e) => this.handleChangeProduct(e)}
					addProductToBasketBySku={this.props.addProductToBasketBySku}
					isFetching={isFetching}
					data={currentProductLister}
					updateCompareProduct={this.props.updateCompareProduct}
					isFetchingCompare={isFetchingCompare}
				/>
			</div>
		);
	}
}

function mapDispatchProps(dispatch) {
	return bindActionCreators(actionCreators, dispatch);
}

function mapStateToProps(state) {
	return {
		productLister: state.productLister,
		localizations: state.localizations,
		extensions: state.extensions,
	};
}

export default connect(mapStateToProps, mapDispatchProps)(ProductListerApp);
