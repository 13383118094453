import React from 'react';
import ProductListerMainHeader from './ProductListerMainHeader';
import ProductListerItems from './ProductListerItems';
import ProductListerFooter from './ProductListerFooter';
import ProductListerEmpty from './ProductListerEmpty';
import ProductListerDescription from './ProductListerDescription';

class ProductListerMain extends React.Component {
	render() {
		const { isFetching, data, localization, extensions } = this.props;

		const isEmpty = !data || data.empty;

		if (data) {
			const quickFilters = data.filters.filter((filter) => {
				return filter.filterElements.length > 0 && filter.quickFilter === true;
			});

			const hasQuickFilters = quickFilters && quickFilters.length > 0;

			return (
				<div id='main' className='main b0_12 b4_17 b6_19 b4_push_01'>
					<div className='g'>
						<div className='b0_12'>
							<div className='p p-b3-0'>
								<h1 className='head head-display mb-2x hide show-b4'>{data.categoryName}</h1>
								{extensions.Lister && <ProductListerDescription />}
								{!isEmpty && (
									<section className={`lister block ${hasQuickFilters ? 'quickfilters' : ''}`}>
										<ProductListerMainHeader
											localization={localization}
											onChange={this.props.onChange}
											paging={data.paging}
											numberOfResults={data.numberOfResults}
											sorting={data.sorting}
											filters={data.filters}
										/>
										<ProductListerItems
											extensions={extensions}
											localization={localization}
											products={data.products}
											onChangeProduct={this.props.onChangeProduct}
											addProductToBasketBySku={this.props.addProductToBasketBySku}
											updateCompareProduct={this.props.updateCompareProduct}
											isFetchingCompare={this.props.isFetchingCompare}
										/>
										<ProductListerFooter
											localization={localization}
											onChange={this.props.onChange}
											paging={data.paging}
										/>
										{isFetching != null && isFetching && (
											<div id='overlayLoading' className='overlay-loader lister-loader'>
												<i className='icon-spin4 animate-spin spinner'></i>
											</div>
										)}
									</section>
								)}

								{isEmpty && extensions.Lister && (
									<section className='lister block'>
										<ProductListerEmpty localization={localization} />
									</section>
								)}
							</div>
						</div>
					</div>
				</div>
			);
		}
		return <div id='main' className='main b0_12 b4_17 b6_19 b4_push_01'></div>;
	}
}

export default ProductListerMain;
