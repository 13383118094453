import React from 'react';
import ProductListerSelectedFiltersItem from './ProductListerSelectedFiltersItem';

class ProductListerSelectedFilters extends React.Component {
	render() {
		const { filters, localization } = this.props;

		let selectedfilterItem = [];

		if (filters != null) {
			selectedfilterItem = filters.map((filter, index) => (
				<ProductListerSelectedFiltersItem
					selectedFilterElements={filter.selectedFilterElements}
					name={filter.name}
					localization={localization}
					index={index}
					key={index}
					onChange={this.props.onChange}
				/>
			));

			if (filters.length > 0) {
				return (
					<div className='filter selected-filters-container'>
						<span className='filter-title head head6 mb'>
							{localization['productlister.selectedfilters.selected.text']}
						</span>
						<div className='filter-content cf'>
							<div className='selected-filters cf'>{selectedfilterItem}</div>
						</div>
					</div>
				);
			}
		}
		return false;
	}
}

export default ProductListerSelectedFilters;
