import React from 'react';
import ProductListerSorting from './ProductListerSorting';
import ProductListerUnselectedQuickFilters from './ProductListerUnselectedQuickFilters';

class ProductListerMainHeader extends React.Component {
	render() {
		const { numberOfResults, sorting, filters, localization } = this.props;

		let numberOfResultsText =
			numberOfResults == 1
				? localization['productlister.header.products.singular']
				: localization['productlister.header.products.plural'];

		const quickFilters = filters.filter((filter) => {
			return filter.filterElements.length > 0 && filter.quickFilter === true;
		});

		return (
			<header className='list-options formfields pb mb-2x alt'>
				<div className='g g-equal-height ai-flex-start'>
					<div className='b0_09 list-options-quickfilters'>
						{quickFilters && (quickFilters.length > 0) &&
							<ProductListerUnselectedQuickFilters
								localization={localization}
								filters={quickFilters}
								onChange={this.props.onChange}
							/>
						}
						<div className='head head5 mb-0 hide show-b4'>
							{numberOfResults} {numberOfResultsText}
						</div>
					</div>
					<div className='b0_12 b4_06'>
						<div className='g'>
							<div className='hide show-b4 b0_12 filled has_select'>
								<div className=''>
									<ProductListerSorting
										localization={localization}
										onChange={this.props.onChange}
										sorting={sorting}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</header>
		);
	}
}

export default ProductListerMainHeader;
