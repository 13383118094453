import {
	RECEIVE_ADDPRODUCTTOBASKET,
	RECEIVE_PRODUCT,
	RECEIVE_PRODUCTLISTER,
	RECEIVE_UPDATECOMPAREPRODUCT,
	REQUEST_ADDPRODUCTTOBASKET,
	REQUEST_PRODUCT,
	REQUEST_PRODUCTLISTER,
	REQUEST_UPDATECOMPAREPRODUCT,
	REQUEST_GETCOMPAREDATA,
	RECEIVE_GETCOMPAREDATA,
} from './actions';

import produce from 'immer';

import { pushProductImpressions } from 'shared/libs/gaevents';

function productLister(state = {}, action) {
	switch (action.type) {
		case RECEIVE_PRODUCTLISTER: {
			const { url, data } = action;
			const { products } = data;
			let productListers = [];
			productListers[url] = data;

			//send GA impressions
			const { currentProductLister } = state;
			const { categoryName } = currentProductLister;
			let isSearch =
				currentProductLister?.search?.searchTerm && currentProductLister?.search?.searchTerm != '';
			let list = isSearch ? 'Search Results' : categoryName;
			pushProductImpressions(products, list);

			let newState = produce(state, (draftState) => {
				draftState.isFetching = false;
				draftState.currentProductListerUrl = url;
				draftState.currentProductLister = data;
				draftState.productListers = Object.assign({}, state.productListers, productListers);
			});

			return newState;
		}
		case REQUEST_PRODUCTLISTER: {
			return Object.assign({}, state, {
				isFetching: true,
			});
		}
		case RECEIVE_PRODUCT: {
			let { index, data } = action;
			index = parseInt(index, 10);

			const { currentProductLister } = state;
			const { products } = currentProductLister;

			let newState = Object.assign({}, state, {
				currentProductLister: Object.assign({}, currentProductLister, {
					products: [].concat([...products.slice(0, index), data, ...products.slice(index + 1)]),
				}),
			});

			return newState;
		}
		case REQUEST_PRODUCT: {
			let { index } = action;
			index = parseInt(index, 10);

			const { currentProductLister } = state;
			const { products } = currentProductLister;

			let newState = Object.assign({}, state, {
				currentProductLister: Object.assign({}, currentProductLister, {
					products: [].concat([
						...products.slice(0, index),
						Object.assign({}, products[index], { isFetching: true }),
						...products.slice(index + 1),
					]),
				}),
			});

			return newState;
		}
		case REQUEST_ADDPRODUCTTOBASKET: {
			let { index } = action;
			index = parseInt(index, 10);

			const { currentProductLister } = state;
			const { products } = currentProductLister;

			var newState = Object.assign({}, state, {
				currentProductLister: Object.assign({}, currentProductLister, {
					products: [].concat([
						...products.slice(0, index),
						Object.assign({}, products[index], { isAddingToBasket: true }),
						...products.slice(index + 1),
					]),
				}),
			});

			return newState;
		}
		case RECEIVE_ADDPRODUCTTOBASKET: {
			let { index, data } = action;
			index = parseInt(index, 10);
			const { currentProductLister } = state;
			const { products } = currentProductLister;

			let newState = Object.assign({}, state, {
				basketQuantity: data.data.quantity,
				currentProductLister: Object.assign({}, currentProductLister, {
					products: [].concat([
						...products.slice(0, index),
						Object.assign({}, products[index], { isAddingToBasket: false, addToBasketQuantity: 1 }),
						...products.slice(index + 1),
					]),
				}),
			});

			return newState;
		}

		case REQUEST_UPDATECOMPAREPRODUCT: {
			let newState = produce(state, (draftState) => {
				draftState.isFetchingCompare = true;
			});

			return newState;
		}

		case RECEIVE_UPDATECOMPAREPRODUCT: {
			var { index, data, compare } = action;
			let newState = produce(state, (draftState) => {
				draftState.isFetchingCompare = false;
				if (data.success) {
					if (index != null) {
						draftState.currentProductLister.products[index].isCompare = compare;
						draftState.compareProductSKUs = data.data;
					}
				}
			});
			return newState;
		}

		case REQUEST_GETCOMPAREDATA: {
			let newState = produce(state, (draftState) => {
				draftState.isFetchingCompare = true;
			});

			return newState;
		}

		case RECEIVE_GETCOMPAREDATA: {
			var { data } = action;

			let newState = produce(state, (draftState) => {
				draftState.isFetchingCompare = false;
				if (data.success) {
					draftState.compareProductSKUs = data.data;
				}
			});

			return newState;
		}

		default:
			return state;
	}
}

function localizations(state = {}, action) {
	return state;
}

function extensions(state = {}, action) {
	return state;
}

function synchronizerToken(state = {}, action) {
	return state;
}

const reducers = {
	productLister,
	localizations,
	extensions,
	synchronizerToken,
};

export default reducers;
